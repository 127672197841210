import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExportToCsv } from "export-to-csv";
import React from "react";
import { UserContext } from "../../common/UserContext";
import {
  AdminComment,
  Application,
  Batch,
  BATCH_STATUS,
  makeRequest,
  PaymentModel,
  Process,
} from "../../common/api";
import { isUserAdmin } from "../../common/auth";
import {
  DataTable,
  DataTableColumnDef,
} from "../../common/components/DataTable";
import { PageBody } from "../../common/components/PageBody";
import { TabBar } from "../../common/components/TabBar";
import { AdminPanel } from "../../common/features/AdminPanel";
import { DynamicForm } from "../../common/features/DynamicForm";
import {
  arrayRemoveItem,
  AttachmentLink,
  formatDurationRough,
  LoadingIndicator,
  Required,
  useTitle,
} from "../../common/features/Utils";
import { DashboardTrendsPage } from "./DashboardTrendsPage";

export const ApplicantDashboardPage = () => {
  useTitle("Applicant Dashboard");
  const user = React.useContext(UserContext)?.user;
  const [applications, setApplications] = React.useState<Application[] | null>(
    null
  );
  const [batches, setBatches] = React.useState<Batch[] | null>(null);
  const [paymentModels, setPaymentModels] = React.useState<
    PaymentModel[] | null
  >(null);
  const [showInactive, setShowInactive] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [currentTab, setCurrentTab] = React.useState<
    "active" | "complete" | "all"
  >("active");
  const [collapsedBatchIds, setCollapsedBatchIds] = React.useState<string[]>(
    []
  );
  const fetchedBatchIdsRef = React.useRef<Set<string>>(new Set<string>());
  const [isShowingTrends, setIsShowingTrends] = React.useState<boolean>(false);
  const [mappings, setMappings] = React.useState<{ [key: string]: string }>({});

  // TODO: Extract this out into a custom singleton dialog
  const [isAddApplicantShowing, setIsAddApplicantShowing] =
    React.useState(false);
  const [batchId, setBatchId] = React.useState("");

  const [processes, setProcesses] = React.useState<Process[] | null>();
  React.useEffect(() => {
    makeRequest("/api/processConfigBlobs", "GET").then((blobs) => {
      setProcesses(
        blobs.map((b) => {
          const parsed = JSON.parse(b.json);
          parsed._id = b._id;
          return parsed;
        })
      );
    });
  }, []);

  React.useEffect(() => {
    makeRequest("/api/batches", "GET").then((data: Batch[]) => {
      data.sort(
        (a, b) =>
          BATCH_STATUS.indexOf(a.status) - BATCH_STATUS.indexOf(b.status)
      );
      const nonOpenBatches = data.filter((batch) => batch.status !== "Open");
      setCollapsedBatchIds(nonOpenBatches.map((b) => b._id));
      setBatches(data);
      const newMappings = {};
      for (let batch of data) {
        newMappings[batch._id] = batch.name;
      }
      setMappings(newMappings);
    });
    makeRequest("/api/paymentModels", "GET").then((models: PaymentModel[]) => {
      setPaymentModels(models);
    });
  }, []);

  const visibleBatches = React.useMemo(
    () =>
      batches?.filter((b) => {
        return (
          (currentTab === "active" &&
            (b.status === "Open" || b.status === "Closed")) ||
          (currentTab === "complete" && b.status === "Complete") ||
          currentTab === "all"
        );
      }) || [],
    [batches, currentTab]
  );

  React.useEffect(() => {
    const batchIds = visibleBatches
      .map((b) => b._id)
      .filter(
        (b) =>
          !collapsedBatchIds.includes(b) && !fetchedBatchIdsRef.current.has(b)
      );
    if (batchIds.length === 0) {
      return;
    }
    const requestUrl = `/api/applications?batchIds=${batchIds.join(",")}`;
    batchIds.forEach((id) => {
      if (!fetchedBatchIdsRef.current.has(id)) {
        fetchedBatchIdsRef.current.add(id);
      }
    });
    makeRequest(requestUrl, "GET").then((data) => {
      data = data || [];
      data.sort((a: Application, b: Application) => {
        return a.createdAt.localeCompare(b.createdAt);
      });
      const newApplications = [...(applications || []), ...data];
      setApplications(newApplications);
    });
  }, [visibleBatches, collapsedBatchIds]);

  const search = searchText.toLowerCase();
  let filteredApplications =
    applications?.filter(
      (a) =>
        (a.firstName &&
          a.lastName &&
          (a.firstName + a.lastName).toLowerCase().includes(search)) ||
        a.email?.toLowerCase().includes(search) ||
        a.status?.toLowerCase().includes(search) ||
        a.adminAssignee?.userEmail?.toLowerCase().includes(search)
    ) || [];

  const pendingInterviews =
    applications?.filter(
      (app) =>
        !!app.calendlyStartTime &&
        Date.parse(app.calendlyStartTime) > Date.now()
    ) || [];

  return (
    <PageBody>
      <div>
        <h2>Applications Dashboard</h2>
        <p>
          This page is a dashboard to view and manage active applications for
          any open batches.
          <label
            style={{ width: "auto", marginLeft: "20px" }}
            title="View an overview chart instead of the list of applications."
          >
            <input
              style={{ width: "auto", marginRight: "10px" }}
              type="checkbox"
              checked={isShowingTrends}
              onChange={(e) => setIsShowingTrends(e.target.checked)}
            />
            View Trends
          </label>
        </p>
      </div>
      <PendingInterviews applications={pendingInterviews} />
      <div className="moreInfo">
        <TabBar
          tabs={["active", "complete", "all"]}
          onClick={(t) => setCurrentTab(t as any)}
          saveToQueryString={true}
        />
        {isShowingTrends && (
          <>
            <DashboardTrendsPage
              applications={applications}
              mappings={mappings}
            />
          </>
        )}
        {!isShowingTrends && (
          <>
            <div>
              <label
                style={{ width: "auto" }}
                title="By default, inactive applications are hidden. To hide an application, change status to CANCELLED"
              >
                <input
                  style={{ width: "auto", marginRight: "10px" }}
                  type="checkbox"
                  checked={showInactive}
                  onChange={(e) => setShowInactive(e.target.checked)}
                />
                Show inactive applications
              </label>
              <label style={{ marginLeft: "20px", width: "auto" }}>
                Search:
                <input
                  style={{
                    borderRadius: "4px",
                    borderWidth: "1px",
                    marginLeft: "10px",
                  }}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </label>
            </div>
            <div>
              {(!applications || !visibleBatches) && (
                <div>
                  <LoadingIndicator /> Loading...
                </div>
              )}
              {applications &&
                visibleBatches &&
                visibleBatches.map((batch) => {
                  let batchApplications = filteredApplications.filter(
                    (app) => app.batchId === batch._id
                  );
                  if (!showInactive) {
                    batchApplications = batchApplications.filter(
                      (app) => app.status !== "CANCELLED"
                    );
                  }

                  // if (batch.status === "Complete" && !showInactive) {
                  //   batchApplications = batchApplications.filter(
                  //     (app) => app.status === "COMPLETE"
                  //   );
                  // }

                  const process = processes?.find(
                    (p) => p._id === batch.processId
                  );

                  const dynamicColumns = process
                    ? process.fields.map((f) => ({
                        label: f.label,
                        type: f.dataType,
                        key: f.name,
                        hidden:
                          f.isAdminOnly &&
                          !isUserAdmin(user, batch.organizationId),
                      }))
                    : [];

                  const columns: DataTableColumnDef[] = [
                    {
                      label: "#",
                      href: (data) => `/application/${data._id}`,
                      renderer: (data) => data.trackingNumber || "inactive",
                    },
                    { label: "Status", key: "status" },
                    { label: "Date", key: "createdAt", type: "date" },
                    {
                      label: "Acct",
                      tooltip: "Whether the user created an account",
                      renderer: (data) => data.userId && "yes",
                    },
                    ...dynamicColumns,
                    {
                      label: "Last seen",

                      renderer: (data) => {
                        if (!data.userActivity?.activityEntries?.length) {
                          return "";
                        } else {
                          const latestEntry =
                            data.userActivity.activityEntries[
                              data.userActivity.activityEntries.length - 1
                            ];
                          const millis =
                            Date.now() -
                            new Date(latestEntry.timestamp).getTime();
                          const text = formatDurationRough(millis) + " ago";
                          return text;
                        }
                      },
                    },
                    {
                      label: "Assessments",
                      key: "assessmentScores",
                      renderer: (data) => {
                        return data.assessmentScores
                          ?.map((as) => as.score + "%")
                          .join(", ");
                      },
                      comparator: (a, b) => {
                        let difference =
                          (a.assessmentScores?.[0]?.score || 0) -
                          (b.assessmentScores?.[0]?.score || 0);
                        return difference;
                      },
                    },
                    {
                      label: "Projects",
                      renderer: (data) => {
                        return (
                          <>
                            {data.projectFiles?.map((f) => (
                              <div key={f._id}>
                                <AttachmentLink file={f} />
                              </div>
                            ))}
                            <span>
                              {data.projectFile && (
                                <AttachmentLink file={data.projectFile} />
                              )}
                            </span>
                          </>
                        );
                      },
                      getSortText: (data) => {
                        const projectFile =
                          data.projectFile || data.projectFiles?.[0];
                        return projectFile ? "Has Project" : "";
                      },
                    },
                    {
                      label: "Plan",
                      renderer: (data) => {
                        return paymentModels.find(
                          (pm) => pm._id === data.selectedPaymentModelId
                        )?.name;
                      },
                      key: "selectedPaymentModelId",
                      hidden:
                        !isUserAdmin(user, batch.organizationId) ||
                        !paymentModels,
                    },
                    {
                      label: "Comments",
                      renderer: (data) => {
                        return <CommentPreview comments={data.adminComments} />;
                      },
                      getSortText: (data) => {
                        if (data.adminComments) {
                          return (
                            data.adminComments[data.adminComments.length - 1]
                              ?.message || ""
                          );
                        }
                        return "";
                      },
                      hidden: !isUserAdmin(user, batch.organizationId),
                    },
                    {
                      label: "Assignee",
                      key: "adminAssignee",
                      comparator: (a, b) => {
                        if (a.adminAssignee && !b.adminAssignee) return -1;
                        if (!a.adminAssignee && b.adminAssignee) return 1;
                        if (!a.adminAssignee && !b.adminAssignee) return 0;
                        return (
                          a.adminAssignee?.userEmail?.localeCompare(
                            b.adminAssignee?.userEmail
                          ) || 0
                        );
                      },
                      hidden: !isUserAdmin(user, batch.organizationId),
                      getSortText: (data) => {
                        return (
                          (data.adminAssignee?.userEmail &&
                            getEmailPrefix(data.adminAssignee?.userEmail)) ||
                          ""
                        );
                      },
                      renderer: (data) => {
                        return (
                          <>
                            {user && data.adminAssignee?.userId === user._id ? (
                              <FontAwesomeIcon
                                icon="star"
                                size="1x"
                                title="Assigned to you"
                              />
                            ) : (
                              ""
                            )}
                            {getEmailPrefix(data.adminAssignee?.userEmail)}
                          </>
                        );
                      },
                    },
                    {
                      label: "Tracking",
                      renderer: (data) => {
                        return data.tracking
                          ? data.tracking.source + " / " + data.tracking.content
                          : "";
                      },
                    },
                  ];

                  const exportBatchToCsv = () => {
                    const data = batchApplications.map((app) => ({
                      trackingNumber: app.trackingNumber || "n/a",
                      status: app.status,
                      firstName: app.firstName,
                      lastName: app.lastName,
                      email: app.email,
                      phoneNumber: app.phoneNumber,
                      initialApplication: app.createdAt,
                      scores: app.assessmentScores.map((s) => s.score),
                      tracking: app.tracking
                        ? app.tracking.source +
                          " / " +
                          app.tracking.content +
                          " / " +
                          app.tracking.campaign
                        : "",
                    }));

                    const csvExporter = new ExportToCsv({
                      fieldSeparator: ",",
                      quoteStrings: '"',
                      decimalSeparator: ".",
                      showLabels: true,
                      showTitle: true,
                      title: batch.name,
                      useTextFile: false,
                      useBom: true,
                      useKeysAsHeaders: true,
                      filename:
                        batch.name +
                        (searchText ? ": " + searchText : "") +
                        " (" +
                        data.length +
                        " rows)",
                    });

                    csvExporter.generateCsv(data);
                  };

                  return (
                    <div key={batch._id}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h3 style={{ display: "inline", marginRight: "10px" }}>
                          {batch.name} ({batch.status})
                        </h3>
                        <button
                          className="inline secondary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            console.log(collapsedBatchIds);
                            if (collapsedBatchIds.includes(batch._id)) {
                              setCollapsedBatchIds(
                                arrayRemoveItem(collapsedBatchIds, batch._id)
                              );
                            } else {
                              setCollapsedBatchIds([
                                ...collapsedBatchIds,
                                batch._id,
                              ]);
                            }
                          }}
                        >
                          {collapsedBatchIds.includes(batch._id)
                            ? "expand"
                            : "collapse"}
                        </button>
                      </div>
                      {collapsedBatchIds.includes(batch._id) ? (
                        <div>
                          {batchApplications.length > 0
                            ? `Collapsed with ${batchApplications.length} applications.`
                            : `Collapsed. Expand to load applications.`}
                        </div>
                      ) : (
                        <div>
                          <p>
                            Showing {batchApplications.length} rows
                            <button
                              className="inline secondary"
                              onClick={exportBatchToCsv}
                            >
                              Download visible data as CSV
                            </button>
                          </p>

                          <DataTable
                            columns={columns}
                            data={batchApplications}
                            canExpand={isUserAdmin(user, batch.organizationId)}
                            canSort={true}
                            canFilter={true}
                            defaultSortKey="createdAt"
                            defaultIsSortAscending={false}
                            renderExpandedRow={(app) => (
                              <AdminPanel
                                application={app}
                                onApplicationChange={(
                                  updatedApplication: Application
                                ) => {
                                  const index = applications.indexOf(app);
                                  const newApplications = [...applications];
                                  newApplications[index] = updatedApplication;
                                  setApplications(newApplications);
                                }}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              <br />
              <br />
              {isAddApplicantShowing && (
                <div>
                  <h3 style={{ marginBottom: "20px" }}>Choose your option:</h3>
                  <div>
                    <label htmlFor="batch">
                      Batch
                      <Required />
                    </label>
                    <select
                      required
                      id="batch"
                      value={batchId}
                      onChange={(e) => setBatchId(e.target.value)}
                    >
                      <option value=""></option>
                      {batches
                        ?.filter((b) => b.status === "Open")
                        .map((batch) => (
                          <option key={batch._id} value={batch._id}>
                            {batch.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {process ? (
                    <DynamicForm
                      configuration={{
                        ...((
                          processes.find(
                            (p) =>
                              p._id ===
                              batches?.find((b) => b._id === batchId)?.processId
                          ) || processes[0]
                        )?.applicationForm || ({} as any)),
                        title: "Add an applicant",
                      }}
                      onSubmitted={(newApplication) => {
                        setIsAddApplicantShowing(false);
                        setApplications([
                          ...(applications || []),
                          newApplication,
                        ]);
                      }}
                      initialEntry={{
                        batchId: visibleBatches?.filter(
                          (b) => b.status === "Open"
                        )[0]?._id,
                      }}
                    />
                  ) : (
                    <LoadingIndicator />
                  )}
                </div>
              )}
              {isAddApplicantShowing ? (
                <button
                  className="secondary"
                  onClick={() => setIsAddApplicantShowing(false)}
                >
                  Cancel
                </button>
              ) : (
                <button
                  className=""
                  onClick={() => setIsAddApplicantShowing(true)}
                >
                  <FontAwesomeIcon icon="plus" /> Add new applicant
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </PageBody>
  );
};

const getEmailPrefix = (email: string | null | undefined) => {
  return email?.split("@")[0];
};

const CommentPreview = (props: { comments: AdminComment[] | null }) => {
  if (!props.comments) {
    return null;
  }

  const lastComment =
    props.comments && props.comments[props.comments.length - 1];
  if (!lastComment) {
    return null;
  }

  return <span>{lastComment.message?.slice(0, 50)}</span>;
};

const PendingInterviews = (props: { applications: Application[] | null }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  if (!isExpanded) {
    return (
      <button onClick={() => setIsExpanded(true)} className="secondary inline">
        See {props.applications?.length || 0} upcoming interviews.
      </button>
    );
  } else {
    const apps = [...(props.applications || [])];
    apps.sort(
      (a, b) =>
        Date.parse(a.calendlyStartTime) - Date.parse(b.calendlyStartTime)
    );
    return (
      <DataTable
        data={apps}
        columns={[
          {
            label: "Name",
            renderer: (a: Application) => a.firstName + " " + a.lastName,
          },
          { label: "Email", key: "email" },
          { label: "Date", key: "calendlyStartTime", type: "timestamp" },
          { label: "Link", key: "calendlyJoinUrl" },
        ]}
      />
    );
  }
};
